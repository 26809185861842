import { type FC } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { withZodSchema } from "formik-validator-zod";
import {
  Workspace,
  BaseWorkspace,
  BaseWorkspaceValidator,
} from "honeygrid-types";
import { useAppDispatch, useAppSelector } from "@/redux/hooks/useAppState";
import { useCreateWorkspace } from "@/queries/workspaces/useCreateWorkspace";
import { useUpdateWorkspace } from "@/queries/workspaces/useUpdateWorkspace";
import {
  setIsWorkspaceEditorOpen,
  setIsWorkspaceSelectorOpen,
  isWorkspaceEditorOpen,
} from "@/redux/store/slices/ui/uiSlice";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";

interface WorkspaceEditorProps {
  workspace?: Workspace | null;
  afterClose?: () => void;
}

export const WorkspaceEditor: FC<WorkspaceEditorProps> = ({
  afterClose,
  workspace,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(isWorkspaceEditorOpen);
  const { mutateAsync: createWorkspace } = useCreateWorkspace();
  const { mutateAsync: updateWorkspace } = useUpdateWorkspace();

  const closeDialog = () => {
    dispatch(setIsWorkspaceEditorOpen(false));
    afterClose?.();
  };

  const formik = useFormik({
    initialValues: {
      name: workspace?.name ?? "",
      background_image: workspace?.background_image ?? "",
    },
    validate: withZodSchema(BaseWorkspaceValidator),
    onSubmit: async (values) => {
      if (!workspace) {
        const draftWorkspace: BaseWorkspace = {
          name: values.name,
          background_image: values.background_image,
        };

        const newWorkspace = await createWorkspace(draftWorkspace);
        navigate(`/${newWorkspace.id}/locations/add`);
        closeDialog();
      } else {
        await updateWorkspace({
          id: workspace.id,
          name: values.name,
          background_image: values.background_image,
        });

        closeDialog();
        dispatch(setIsWorkspaceSelectorOpen(true));
      }
    },
    enableReinitialize: true,
  });

  return (
    <Dialog open={isOpen} onOpenChange={(open) => !open && closeDialog()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {workspace ? "Update" : "Create new"} workspace
          </DialogTitle>
        </DialogHeader>

        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <div className="space-y-2">
            <Input
              name="name"
              placeholder="Workspace Name"
              value={formik.values.name}
              onChange={formik.handleChange}
              className={
                formik.touched.name && formik.errors.name
                  ? "border-destructive"
                  : ""
              }
            />
            {formik.touched.name && formik.errors.name && (
              <p className="text-sm text-destructive">{formik.errors.name}</p>
            )}
          </div>

          <DialogFooter>
            <Button variant="outline" onClick={closeDialog} type="button">
              Cancel
            </Button>
            <Button disabled={!formik.isValid} type="submit">
              Save
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};
