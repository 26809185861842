import { z } from "zod";

export const PhotoValidator = z.object({
  id: z.string(),
  location_id: z.string(),
  height: z.number(),
  width: z.number(),
  photo_reference: z.string(),
});

export const BasePhotoValidator = PhotoValidator.omit({
  id: true,
  location_id: true,
});

export const LocationValidator = z.object({
  id: z.string(),
  address: z.string(),
  phone_number: z.string(),
  latitude: z.number(),
  longitude: z.number(),
  name: z.string(),
  place_id: z.string(),
  rating: z.number(),
  url: z.string(),
  ratings_total: z.number(),
  website: z.string().nullable(),
  types: z.array(z.string()),
  created_at: z.string(),
  updated_at: z.string(),
});

export const BaseLocationValidator = LocationValidator.omit({
  id: true,
  created_at: true,
  updated_at: true,
});

export const OpeningHoursPeriodValidator = z.object({
  id: z.string(),
  location_id: z.string(),
  open_day: z.number(),
  open_time: z.string(),
  close_day: z.number(),
  close_time: z.string(),
});

export const BaseOpeningHoursPeriodValidator = OpeningHoursPeriodValidator.omit(
  {
    id: true,
    location_id: true,
  }
);

export const ReviewValidator = z.object({
  id: z.string(),
  location_id: z.string(),
  author_name: z.string(),
  author_url: z.string(),
  language: z.string(),
  original_language: z.string(),
  profile_photo_url: z.string(),
  rating: z.number(),
  relative_time_description: z.string(),
  text: z.string(),
  time: z.number(),
  translated: z.boolean(),
});

export const BaseReviewValidator = ReviewValidator.omit({
  id: true,
  location_id: true,
});

// Inferred types
export type Photo = z.infer<typeof PhotoValidator>;
export type BasePhoto = z.infer<typeof BasePhotoValidator>;
export type Location = z.infer<typeof LocationValidator>;
export type BaseLocation = z.infer<typeof BaseLocationValidator>;
export type OpeningHoursPeriod = z.infer<typeof OpeningHoursPeriodValidator>;
export type BaseOpeningHoursPeriod = z.infer<
  typeof BaseOpeningHoursPeriodValidator
>;
export type Review = z.infer<typeof ReviewValidator>;
export type BaseReview = z.infer<typeof BaseReviewValidator>;

export type FullLocation = Location & {
  photos: Photo[];
  opening_hours: OpeningHoursPeriod[];
  reviews: Review[];
};
