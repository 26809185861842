import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { User } from "honeygrid-types";
import { FeatureNames } from "../names";
import { RootState } from "../../initStore";

export interface AuthState {
  user: User | null;
  isLoginDialogOpen: boolean;
}

const initialState: AuthState = {
  user: null,
  isLoginDialogOpen: false,
};

export const authSlice = createSlice({
  name: FeatureNames.Auth,
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setIsLoginDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isLoginDialogOpen = action.payload;
    },
    logOut: () => {
      return initialState;
    },
  },
});

export const { setUser, setIsLoginDialogOpen } = authSlice.actions;

// Selectors
export const userSelector = (state: RootState) => state.auth.user;
export const isLoginDialogOpen = (state: RootState) =>
  state.auth.isLoginDialogOpen;
