import React, {
  FC,
  useEffect,
  useState,
  ReactNode,
  useContext,
  createContext,
  useRef,
} from "react";
import { createClient } from "@openauthjs/openauth/client";
import { useNavigate, useLocation } from "react-router-dom";
import { Routes } from "@/routes";

const client = createClient({
  clientID: "web-app",
  issuer: `${import.meta.env.VITE_API_URL}`,
});

interface AuthContextType {
  userId?: string;
  loaded: boolean;
  loggedIn: boolean;
  logout: () => void;
  login: () => Promise<void>;
  getToken: () => Promise<string | undefined>;
  email?: string;
}

const AuthContext = createContext({} as AuthContextType);

const AuthLoader: FC<{ children: React.ReactNode }> = ({ children }) => {
  const { loaded, loggedIn, login } = useAuth();

  useEffect(() => {
    console.log("AuthLoader useEffect triggered", {
      loaded,
      loggedIn,
    });
    if (loaded && !loggedIn) {
      console.log("Logging in");
      login();
    }
  }, [loaded, loggedIn, login]);

  if (!loaded || !loggedIn) {
    return null;
  }

  return children;
};

export { AuthLoader };

export function AuthProvider({ children }: { children: ReactNode }) {
  const initializing = useRef(true);
  const [loaded, setLoaded] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const token = useRef<string | undefined>(undefined);
  const [userId, setUserId] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log("Auth useEffect triggered", {
      loaded,
      loggedIn,
      location: location.pathname,
    });
    if (loaded && loggedIn && location.pathname === "/") {
      console.log("Navigating to dashboard");
      navigate(Routes.Dashboard);
    }
  }, [loaded, loggedIn, location.pathname, navigate]);

  useEffect(() => {
    console.log("Second Auth useEffect triggered");
    const hash = new URLSearchParams(location.search.slice(1));
    const code = hash.get("code");
    const state = hash.get("state");

    console.log("Parsed URL parameters:", { code, state });

    if (!initializing.current) {
      console.log("Already initialized, skipping...");
      return;
    }

    initializing.current = false;
    console.log("Initialization set to false");

    if (code && state) {
      console.log("Code and state found, calling callback");
      callback(code, state);
      return;
    }

    console.log("No code or state found, calling auth");
    auth();
  }, []);

  async function auth() {
    const token = await refreshTokens();

    if (token) {
      await user();
    }
    setLoaded(true);
  }

  async function refreshTokens() {
    const refresh = localStorage.getItem("refresh");
    if (!refresh) return;
    const next = await client.refresh(refresh, {
      access: token.current,
    });
    if (next.err) return;
    if (!next.tokens) return token.current;

    localStorage.setItem("refresh", next.tokens.refresh);
    token.current = next.tokens.access;

    return next.tokens.access;
  }

  async function getToken() {
    const token = await refreshTokens();

    if (!token) {
      await login();
      return;
    }

    return token;
  }

  async function login() {
    const { challenge, url } = await client.authorize(
      window.location.origin,
      "code",
      {
        pkce: true,
      }
    );
    sessionStorage.setItem("challenge", JSON.stringify(challenge));
    window.location.href = url;
  }

  async function callback(code: string, state: string) {
    const challenge = JSON.parse(sessionStorage.getItem("challenge")!);
    if (code) {
      if (state === challenge.state && challenge.verifier) {
        const exchanged = await client.exchange(
          code!,
          window.location.origin,
          challenge.verifier
        );
        if (!exchanged.err) {
          token.current = exchanged.tokens?.access;
          localStorage.setItem("refresh", exchanged.tokens.refresh);
          await user();
          setLoaded(true);
        }
      }
      navigate(Routes.Dashboard);
    }
  }

  async function user() {
    const res = await fetch(`${import.meta.env.VITE_API_URL}/jwt/users/me`, {
      headers: {
        Authorization: `Bearer ${token.current}`,
      },
    });

    if (res.ok) {
      const data = await res.json();
      setUserId(data.id);
      setEmail(data.email);
      setLoggedIn(true);
    } else {
      logout();
    }
  }

  function logout() {
    localStorage.removeItem("refresh");
    token.current = undefined;
    setLoggedIn(false);
    setUserId(undefined);
    setEmail(undefined);
    window.location.replace("/");
  }

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        userId,
        loaded,
        loggedIn,
        getToken,
        email,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
