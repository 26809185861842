import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Workspace } from "honeygrid-types";
import { useHoneyGridApiFetch } from "@/queries/useHoneyGridApiFetch";
import { workspacesQueryKey } from "./useWorkspaces";

export const useUpdateWorkspace = () => {
  const queryClient = useQueryClient();
  const authFetch = useHoneyGridApiFetch();

  return useMutation({
    mutationFn: (workspace: Partial<Workspace>) =>
      authFetch(`workspaces/${workspace.id}`, {
        method: "PATCH",
        body: workspace,
      }) as Promise<Pick<Workspace, "id">>,
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: workspacesQueryKey });
      queryClient.invalidateQueries({
        queryKey: [...workspacesQueryKey, variables.id],
      });
    },
  });
};
