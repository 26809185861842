import { ReferenceFields } from "../geo";
import { z } from "zod";

export const TargetingFilterValidator = z.record(
  z.union([z.nativeEnum(ReferenceFields), z.string()]),
  z.record(z.literal("percentage"), z.number())
);

export const CoordinateValidator = z.object({
  lat: z.number(),
  lng: z.number(),
  radius: z.number(),
  locationId: z.string(),
});

export const TargetedAreasValidator = z.object({
  counties: z.array(z.string()),
  zipCodes: z.array(z.string()),
  coordinates: z.array(CoordinateValidator),
});

export const TargetingTypeValidator = z.enum(["radius", "county", "zip code"]);

export const EntityStatusValidator = z.enum(["active", "archived"]);

export const GridValidator = z.object({
  id: z.string(),
  name: z.string(),
  created_at: z.string(),
  updated_at: z.string(),
  status: EntityStatusValidator,
  manual_inclusion_filters: z.array(z.string()),
  manual_exclusion_filters: z.array(z.string()),
  targeting_filters: TargetingFilterValidator,
  targeted_areas: TargetedAreasValidator,
  households: z.number(),
  targeting_type: TargetingTypeValidator,
  targeting_coverage: z.number(),
  targeted_households: z.number(),
});

export const BaseGrid = GridValidator.omit({
  id: true,
  created_at: true,
  updated_at: true,
  status: true,
});

export type BaseGrid = z.infer<typeof BaseGrid>;
export type Grid = z.infer<typeof GridValidator>;
export type TargetingFilter = z.infer<typeof TargetingFilterValidator>;
export type TargetedAreas = z.infer<typeof TargetedAreasValidator>;
export type TargetingType = z.infer<typeof TargetingTypeValidator>;
export type Coordinate = z.infer<typeof CoordinateValidator>;
