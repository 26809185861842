import {
  type Action,
  type ThunkAction,
  configureStore,
  combineReducers,
} from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import { authSlice } from "./slices/auth/authSlice";
import { mapSlice } from "./slices/map/mapSlice";
import { themeSlice } from "./slices/theme/themeSlice";
import { uiSlice } from "./slices/ui/uiSlice";
import { gridSlice } from "./slices/grid/gridSlice";
import { connectionSlice } from "./slices/connections/connectionsSlice";

export const rootReducer = combineReducers({
  auth: authSlice.reducer,
  map: mapSlice.reducer,
  theme: themeSlice.reducer,
  ui: uiSlice.reducer,
  grid: gridSlice.reducer,
  connection: connectionSlice.reducer,
});

/**
 * Helper function to setup redux store
 * This let's us use this here as well as in test setup
 * @returns initialized store
 */
export const setupStore = (preloadedState?: Partial<RootState>) => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
  });
  setupListeners(store.dispatch);
  return store;
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
