import { FC } from "react";
import { HoneyGridIcon } from "@/icons";
import { type Grid as GridType } from "honeygrid-types";

interface TargetingAreasMetricRowProps {
  grid: GridType;
}

export const TargetingAreasMetricRow: FC<TargetingAreasMetricRowProps> = ({
  grid,
}) => {
  const numFilters = Object.keys(grid.targeting_filters).length;
  const filtersLabel = numFilters === 1 ? "filter" : "filters";

  let targetedAreasLabel = "";

  if (grid.targeting_type === "county") {
    const countyCount = grid.targeted_areas?.counties?.length ?? 0;
    targetedAreasLabel = `${countyCount} ${
      countyCount === 1 ? "county" : "counties"
    }`;
  } else if (grid.targeting_type === "zip code") {
    const zipCount = grid.targeted_areas?.zipCodes?.length ?? 0;
    targetedAreasLabel = `${zipCount} ${
      zipCount === 1 ? "zip code" : "zip codes"
    }`;
  } else if (grid.targeting_type === "radius") {
    const radius = grid.targeted_areas?.coordinates?.[0]?.radius ?? 0;
    targetedAreasLabel = `${radius} miles`;
  }

  return (
    <div className="flex items-center gap-2">
      <HoneyGridIcon className="h-4 w-4" />
      <p className="text-sm">
        {targetedAreasLabel} ({`${numFilters} ${filtersLabel}`})
      </p>
    </div>
  );
};
