import { FC, PropsWithChildren, ReactElement } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { AuthLoader, AuthProvider, PlacesLoader } from "@/loaders";
import { store as defaultStore } from "@/redux/store/initStore";
import { SnackbarProvider } from "notistack";
import { TanStackQueryProvider } from "./TanStackQuery";

import { ThemeProvider } from "./Theme";

interface PropsWithStore extends PropsWithChildren {
  store?: typeof defaultStore;
}

export const AppProvider: FC<PropsWithStore> = ({
  children,
  store = defaultStore,
}) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <AuthProvider>
          <AuthLoader>
            <TanStackQueryProvider>
              <PlacesLoader>
                <ThemeProvider>
                  <SnackbarProvider maxSnack={3}>{children}</SnackbarProvider>
                </ThemeProvider>
              </PlacesLoader>
            </TanStackQueryProvider>
          </AuthLoader>
        </AuthProvider>
      </BrowserRouter>
    </Provider>
  ) as ReactElement<any, any>;
};
