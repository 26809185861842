import { z } from "zod";

export const AddressValidator = z.object({
  street_address: z.string(),
  city: z.string(),
  state: z.string(),
  postal_code: z.string(),
});

export type AddressType = z.infer<typeof AddressValidator>;

const AddressComponentValidator = z.object({
  long_name: z.string(),
  short_name: z.string(),
  types: z.array(z.string()),
});

const LocationValidator = z.object({
  lat: z.number(),
  lng: z.number(),
});

const NavigationPointValidator = z.object({
  location: z.object({
    latitude: z.number(),
    longitude: z.number(),
  }),
});

export const GeocodeResponseValidator = z.object({
  results: z.array(
    z.object({
      address_components: z.array(AddressComponentValidator),
      formatted_address: z.string(),
      geometry: z.object({
        bounds: z
          .object({
            northeast: LocationValidator,
            southwest: LocationValidator,
          })
          .optional(),
        location: LocationValidator,
        location_type: z.string(),
        viewport: z.object({
          northeast: LocationValidator,
          southwest: LocationValidator,
        }),
      }),
      navigation_points: z.array(NavigationPointValidator).optional(),
      partial_match: z.boolean().optional(),
      place_id: z.string(),
      types: z.array(z.string()),
    })
  ),
  status: z.string(),
  error_message: z.string().optional(),
});

export type GeocodeResponseType = z.infer<typeof GeocodeResponseValidator>;
