import { FC, PropsWithChildren, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useHoneyGridApiFetch } from "@/queries/useHoneyGridApiFetch";

// Query keys for WebSocket-driven queries
const WS_QUERY_KEYS = ["targetedAreas"] as const;

export const TanStackQueryProvider: FC<PropsWithChildren> = ({ children }) => {
  const authFetch = useHoneyGridApiFetch();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: (queryKey) => {
              // Set infinite stale time for WebSocket-driven queries
              if (
                Array.isArray(queryKey) &&
                WS_QUERY_KEYS.includes(queryKey[0])
              ) {
                return Infinity;
              }
              // Default stale time for other queries
              return 60 * 1000;
            },
            retry: 1,
            refetchOnWindowFocus: false,
            queryFn: ({ queryKey }) => {
              // Default query function that uses the query key as the endpoint
              return authFetch(queryKey.join("/"));
            },
          },
        },
      })
  );

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
