import { ReferenceFields } from "honeygrid-types/geo";

export const referenceFieldStringMap = {
  [ReferenceFields.CustomerCount]: "Customers",
  [ReferenceFields.CustomerSales]: "Sales",
  [ReferenceFields.CustomerMarketShare]: "Market share",
  [ReferenceFields.Households]: "Households",
  [ReferenceFields.HomeValues]: "Home values",
  [ReferenceFields.Population]: "Population",
  [ReferenceFields.Income]: "Income",
  [ReferenceFields.NewMoverTotal]: "New movers",
  [ReferenceFields.NewMoverSFDU]: "Single-family new movers",
  [ReferenceFields.NewMoverMFDU]: "Multi-family new movers",
  [ReferenceFields.SFDU]: "Single-family dwellings",
  [ReferenceFields.MFDU]: "Multi-family dwellings",
} as const;

export const enabledReferenceFields = [
  ReferenceFields.NewMoverTotal,
  ReferenceFields.Households,
  ReferenceFields.Income,
  ReferenceFields.HomeValues,
  ReferenceFields.SFDU,
  ReferenceFields.MFDU,
] as ReferenceFields[];

export const isValidReferenceField = (
  key: string
): key is (typeof enabledReferenceFields)[number] => {
  return enabledReferenceFields.includes(
    key as (typeof enabledReferenceFields)[number]
  );
};
