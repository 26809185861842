import { FC, Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { Routes as NamedRoutes } from "@/routes";
import { Loading } from "../Loading";
import { Success } from "../Success/Success";
import { WorkspaceEditor } from "../WorkspaceEditor";
import { Toaster } from "sonner";

const Workspace = lazy(() =>
  import("@/components/Workspace").then(({ WorkspaceContainer }) => ({
    default: WorkspaceContainer,
  }))
);
const Dashboard = lazy(() =>
  import("@/components/Dashboard/Dashboard").then(({ Dashboard }) => ({
    default: Dashboard,
  }))
);

export const App: FC = () => {
  return (
    <div className="min-h-screen">
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path={NamedRoutes.Dashboard} element={<Dashboard />} />
          <Route path={NamedRoutes.Success} element={<Success />} />
          <Route path=":workspaceId/*" element={<Workspace />} />
          <Route
            key={NamedRoutes.WildCard}
            path={NamedRoutes.WildCard}
            element={<Navigate to={NamedRoutes.Default} />}
          />
        </Routes>
        <WorkspaceEditor />
        <Toaster />
      </Suspense>
    </div>
  );
};
