import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { Theme } from "@/theme";
import { FeatureNames } from "../names";
import { RootState } from "../../initStore";

import { authSlice } from "../auth/authSlice";

export interface ThemeState {
  theme: Theme;
}

const initialState: ThemeState = {
  theme: Theme.Light,
};

export const themeSlice = createSlice({
  name: FeatureNames.Theme,
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<Theme>) => {
      state.theme = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authSlice.actions.logOut, () => initialState);
  },
});

export const { setTheme } = themeSlice.actions;

// Selectors
export const themeSelector = (state: RootState) => state.theme.theme;

export type ThemeActions = typeof themeSlice.actions;
