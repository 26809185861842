import { useQuery } from "@tanstack/react-query";
import { Workspace } from "honeygrid-types";
import { useHoneyGridApiFetch } from "@/queries/useHoneyGridApiFetch";

export const workspacesQueryKey = ["workspaces"] as const;

export const useWorkspaces = () => {
  const authFetch = useHoneyGridApiFetch();

  return useQuery<Workspace[]>({
    queryKey: workspacesQueryKey,
    queryFn: () => authFetch("workspaces"),
  });
};
