import { faker } from "@faker-js/faker";

import {
  BaseConnection,
  BaseGrid,
  Connection,
  ConnectionPlatforms,
  ConnectionStatus,
  Grid,
  Location,
  TargetingFilter,
  Workspace,
} from ".";
import { Account } from "./Account";
import { Campaign, CampaignStatus } from "./Campaign";
import { County, ZipCode } from "./CustomTargetingBoundary";
import { User } from "./User";
import { Role } from "./common";
import {
  AggregatedDemographics,
  HoneygridAPIH3CellProperties,
  ReferenceFields,
} from "./geo";

export const generateConnection = (
  overrides: Partial<Connection> = {}
): Connection => ({
  id: faker.string.uuid(),
  name: faker.company.name(),
  status: "active",
  accountId: faker.string.uuid(),
  connectionAuthId: faker.string.uuid(),
  platform: ConnectionPlatforms.GoogleAds,
  createdAt: faker.date.past().toISOString(),
  updatedAt: faker.date.past().toISOString(),
  createdBy: faker.string.uuid(),
  rolesOwner: [faker.string.uuid()],
  rolesEditor: [faker.string.uuid()],
  rolesViewer: [faker.string.uuid()],
  cellCount: faker.number.int({ min: 100, max: 10000 }),
  ...overrides,
});

export const generateBaseConnection = ({
  overrides = {},
  empty = false,
}: {
  overrides?: Partial<BaseConnection>;
  empty: boolean;
}): BaseConnection => ({
  name: empty ? "" : faker.company.name(),
  status: ConnectionStatus.Pending,
  accountId: empty ? "" : faker.string.uuid(),
  connectionAuthId: empty ? "" : faker.string.uuid(),
  platform: ConnectionPlatforms.GoogleAds,
  rolesOwner: empty ? [] : [faker.string.uuid()],
  rolesEditor: empty ? [] : [faker.string.uuid()],
  rolesViewer: empty ? [] : [faker.string.uuid()],
  cellCount: empty ? 0 : faker.number.int({ min: 100, max: 10000 }),
  ...overrides,
});

export const generateCampaign = (
  overrides: Partial<Campaign> = {}
): Campaign => ({
  id: faker.string.uuid(),
  name: faker.company.name(),
  budget: {
    amount: faker.number.int({ min: 100, max: 1000 }),
    period: "monthly",
    stripeCustomerId: faker.string.uuid(),
    stripeSubscriptionId: faker.string.uuid(),
  },
  status: CampaignStatus.ACTIVE,
  leadTracking: faker.string.uuid(),
  grid: faker.string.uuid(),
  library: faker.string.uuid(),
  createdAt: faker.date.past().toISOString(),
  results: [],
  googleAdsCampaignId: faker.string.uuid(),
  googleAdsCampaignName: faker.lorem.words(3),
  ...overrides,
});

export const generateWorkspace = (
  overrides: Partial<Workspace> = {}
): Workspace => ({
  id: faker.string.uuid(),
  name: faker.company.name(),
  status: "active",
  account_id: "",
  background_image: faker.image.url(),
  created_at: faker.date.past().toISOString(),
  campaign_count: 0,
  connection_count: 0,
  grid_count: 0,
  landing_page_count: 0,
  location_count: 0,
  ...overrides,
});

export const generateUser = (overrides: Partial<User> = {}): User => ({
  id: faker.string.uuid(),
  email: faker.internet.email(),
  first_name: faker.person.firstName(),
  last_name: faker.person.lastName(),
  created_date: faker.date.past().toISOString(),
  ...overrides,
});

export const generateGrid = ({
  overrides = {},
  empty = false,
}: {
  overrides?: Partial<Grid>;
  empty?: boolean;
} = {}): Grid => ({
  id: empty ? "" : faker.string.uuid(),
  status: "active",
  created_at: faker.date.past().toISOString(),
  updated_at: faker.date.past().toISOString(),
  ...generateBaseGrid({ empty, overrides: overrides }),
  ...overrides,
});

export const generateBaseGrid = ({
  overrides = {},
  empty = false,
}: {
  overrides?: Partial<BaseGrid>;
  empty?: boolean;
}): BaseGrid => ({
  name: empty ? "" : faker.location.city(),
  targeting_filters: {},
  households: empty ? 0 : faker.number.int({ min: 100, max: 10000 }),
  targeting_type: "radius",
  manual_inclusion_filters: [],
  manual_exclusion_filters: [],
  targeted_areas: {
    counties: [],
    zipCodes: [],
    coordinates: [],
  },
  targeting_coverage: empty ? 0 : faker.number.int({ min: 0, max: 100 }),
  targeted_households: empty ? 0 : faker.number.int({ min: 0, max: 10000 }),
  ...overrides,
});

export const generateTargetingFilters = (): TargetingFilter => ({
  [ReferenceFields.Income]: {
    percentage: 0,
  },
  [ReferenceFields.NewMoverTotal]: {
    percentage: 0,
  },
  [ReferenceFields.HomeValues]: {
    percentage: 0,
  },
});

export const generateLocation = (
  overrides: Partial<Location> = {}
): Location => ({
  id: faker.string.uuid(),
  name: faker.company.name(),
  created_at: faker.date.past().toISOString(),
  updated_at: faker.date.past().toISOString(),
  address: faker.location.streetAddress(),
  place_id: faker.string.uuid(),
  phone_number: faker.phone.number(),
  latitude: faker.location.latitude(),
  longitude: faker.location.longitude(),
  rating: faker.number.float({ min: 0.1, max: 5.0, precision: 0.1 }),
  ratings_total: faker.number.int({ min: 100, max: 10000 }),
  website: null,
  url: faker.internet.url(),
  types: ["business"],
  ...overrides,
});

export const generateAccount = (overrides: Partial<Account> = {}): Account => ({
  id: faker.string.uuid(),
  subscription: "standard",
  name: "default",
  active: true,
  locations: 2,
  stripeId: faker.string.uuid(),
  stripeLink: faker.internet.url(),
  stripeCustomerPortalLink: faker.internet.url(),
  roles: {
    [Role.Owner]: [faker.string.uuid()],
    [Role.Editor]: [faker.string.uuid()],
    [Role.Viewer]: [faker.string.uuid()],
  },
  createdAt: faker.date.past().toISOString(),
  updatedAt: faker.date.past().toISOString(),
  ...overrides,
});

export const generateAggregatedDemographics = (
  overrides: Partial<AggregatedDemographics> = {}
): AggregatedDemographics => ({
  averages: {
    [ReferenceFields.Income]: faker.number.float({
      min: 1000,
      max: 100000,
      precision: 0.02,
    }),
    [ReferenceFields.NewMoverTotal]: faker.number.int({ min: 0, max: 1000 }),
    [ReferenceFields.HomeValues]: faker.number.float({
      min: 50000,
      max: 1000000,
      precision: 0.02,
    }),
    [ReferenceFields.CustomerCount]: faker.number.int({ min: 0, max: 1000 }),
    [ReferenceFields.CustomerSales]: faker.number.int({ min: 0, max: 100000 }),
    [ReferenceFields.CustomerMarketShare]: faker.number.float({
      min: 0,
      max: 1,
      precision: 0.02,
    }),
    [ReferenceFields.Households]: faker.number.int({ min: 0, max: 10000 }),
    [ReferenceFields.Population]: faker.number.int({ min: 0, max: 10000 }),
    [ReferenceFields.NewMoverSFDU]: faker.number.int({ min: 0, max: 1000 }),
    [ReferenceFields.NewMoverMFDU]: faker.number.int({ min: 0, max: 1000 }),
    [ReferenceFields.SFDU]: faker.number.int({ min: 0, max: 1000 }),
    [ReferenceFields.MFDU]: faker.number.int({ min: 0, max: 1000 }),
  },
  sortedCellIds: {
    [ReferenceFields.Income]: [
      faker.string.uuid(),
      faker.string.uuid(),
      faker.string.uuid(),
    ],
    [ReferenceFields.NewMoverTotal]: [
      faker.string.uuid(),
      faker.string.uuid(),
      faker.string.uuid(),
    ],
    [ReferenceFields.HomeValues]: [
      faker.string.uuid(),
      faker.string.uuid(),
      faker.string.uuid(),
    ],
    [ReferenceFields.CustomerCount]: [
      faker.string.uuid(),
      faker.string.uuid(),
      faker.string.uuid(),
    ],
    [ReferenceFields.CustomerSales]: [
      faker.string.uuid(),
      faker.string.uuid(),
      faker.string.uuid(),
    ],
    [ReferenceFields.CustomerMarketShare]: [
      faker.string.uuid(),
      faker.string.uuid(),
      faker.string.uuid(),
    ],
    [ReferenceFields.Households]: [
      faker.string.uuid(),
      faker.string.uuid(),
      faker.string.uuid(),
    ],
    [ReferenceFields.Population]: [
      faker.string.uuid(),
      faker.string.uuid(),
      faker.string.uuid(),
    ],
    [ReferenceFields.NewMoverSFDU]: [
      faker.string.uuid(),
      faker.string.uuid(),
      faker.string.uuid(),
    ],
    [ReferenceFields.NewMoverMFDU]: [
      faker.string.uuid(),
      faker.string.uuid(),
      faker.string.uuid(),
    ],
    [ReferenceFields.SFDU]: [
      faker.string.uuid(),
      faker.string.uuid(),
      faker.string.uuid(),
    ],
    [ReferenceFields.MFDU]: [
      faker.string.uuid(),
      faker.string.uuid(),
      faker.string.uuid(),
    ],
  },
  cells: {
    [ReferenceFields.Income]: generateHoneygridAPIH3CellProperties(),
    [ReferenceFields.NewMoverTotal]: generateHoneygridAPIH3CellProperties(),
    [ReferenceFields.HomeValues]: generateHoneygridAPIH3CellProperties(),
  },
  ...overrides,
});

export const generateCounty = (overrides: Partial<County> = {}): County => ({
  id: faker.string.uuid(),
  name: faker.location.county(),
  state: faker.location.state(),
  type: "county",
  metadata: {},
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [-73.982, 40.753],
        [-73.991, 40.745],
        [-73.991, 40.751],
        [-73.982, 40.753],
      ],
    ],
  },
  ...overrides,
});

export const generateHoneygridAPIH3CellProperties = (
  overrides: Partial<HoneygridAPIH3CellProperties> = {}
): HoneygridAPIH3CellProperties => ({
  id: faker.string.uuid(),
  center: [faker.location.latitude(), faker.location.longitude()],
  censusHouseholds: faker.number.int({ min: 100, max: 10000 }),
  uspsHouseholds: faker.number.int({ min: 100, max: 10000 }),
  consensusHouseholds: faker.number.int({ min: 100, max: 10000 }),
  customerCount: faker.number.int({ min: 100, max: 10000 }),
  customerSales: faker.number.int({ min: 100, max: 10000 }),
  customerMarketShare: faker.number.float({ min: 0, max: 1 }),
  ...overrides,
});

export const generateZipCode = (overrides: Partial<ZipCode> = {}): ZipCode => ({
  id: faker.location.zipCode(),
  name: faker.location.zipCode(),
  state: faker.location.state(),
  type: "zipcode",
  metadata: {},
  geometry: {
    type: "Polygon",
    coordinates: [
      [
        [-73.982, 40.753],
        [-73.991, 40.745],
        [-73.991, 40.751],
        [-73.982, 40.753],
      ],
    ],
  },
  ...overrides,
});
