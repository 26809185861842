import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  EXCLUSION_TOOL,
  INCLUSION_TOOL,
  Grid,
  LayerState,
  LayerType,
  MapTool,
  ReferenceFields,
} from "honeygrid-types";
import { VisibilityState } from "@tanstack/react-table";

import { authSlice } from "../auth/authSlice";
import { FeatureNames } from "../names";
import { RootState } from "../../initStore";

export interface MapState {
  layers: LayerState;
  selectedTool: MapTool | null;
  /**
   * This is a list of cell ids that are currently selected by the user via the stats table.
   */
  selectedCells: string[];
  selectedReferenceField: ReferenceFields | string | null;
  selectedGrid?: Grid["id"]; // Selected grid state
  statsTableColumnVisibility: VisibilityState;
}

const initialState: MapState = {
  layers: {
    zipcodes: false,
    counties: false,
  },
  selectedTool: null,
  selectedCells: [],
  selectedReferenceField: null,
  selectedGrid: undefined,
  statsTableColumnVisibility: {
    id: false,
    age: false,
    businesses: false,
    sfdu: false,
    mfdu: false,
  },
};

export const mapSlice = createSlice({
  name: FeatureNames.Map,
  initialState,
  reducers: {
    toggleLayerVisibility: (state, action: PayloadAction<LayerType>) => {
      const layer = action.payload;
      state.layers[layer.id] = !state.layers[layer.id];
    },
    toggleTool: (state, action: PayloadAction<MapTool>) => {
      state.selectedTool =
        state.selectedTool === action.payload ? null : action.payload;
    },
    setSelectedCells: (state, action: PayloadAction<string[]>) => {
      state.selectedCells = action.payload;
    },
    setSelectedGrid: (state, action: PayloadAction<string>) => {
      state.selectedGrid = action.payload;
    },
    setSelectedReferenceField: (
      state,
      action: PayloadAction<MapState["selectedReferenceField"]>
    ) => {
      state.selectedReferenceField = action.payload;
    },
    setStatsTableColumnVisibility: (
      state,
      action: PayloadAction<VisibilityState>
    ) => {
      state.statsTableColumnVisibility = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authSlice.actions.logOut, () => initialState);
  },
});

export const {
  toggleLayerVisibility,
  toggleTool,
  setSelectedCells,
  setSelectedGrid,
  setSelectedReferenceField,
  setStatsTableColumnVisibility,
} = mapSlice.actions;

// Selectors
export const zipCodeMapLayerSelector = (state: RootState) =>
  state.map.layers.zipcodes;
export const countyMapLayerSelector = (state: RootState) =>
  state.map.layers.counties;
export const mapLayersSelector = (state: RootState) => state.map.layers;
export const inclusionToolEnabledSelector = (state: RootState) =>
  state.map.selectedTool === INCLUSION_TOOL;
export const exclusionToolEnabledSelector = (state: RootState) =>
  state.map.selectedTool === EXCLUSION_TOOL;
export const selectedToolSelector = (state: RootState) =>
  state.map.selectedTool;
export const isToolEnabledSelector = (state: RootState) =>
  state.map.selectedTool !== null;
export const selectedCellsSelector = (state: RootState) =>
  state.map.selectedCells;
export const selectedReferenceFieldSelector = (state: RootState) =>
  state.map.selectedReferenceField as ReferenceFields;
export const selectedGridIdSelector = (state: RootState) =>
  state.map.selectedGrid;
export const statsTableColumnVisibilitySelector = (state: RootState) =>
  state.map.statsTableColumnVisibility;
