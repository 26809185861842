import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  BaseGrid,
  MapTool,
  ReferenceFields,
  generateBaseGrid,
} from "honeygrid-types";

import { FeatureNames } from "../names";
import { RootState } from "../../initStore";

// middleware that watched for new data in getGridById
// set the grid state to the new data

export interface GridState {
  grid: BaseGrid | null; // Draft grid state
  targeting_filters: BaseGrid["targeting_filters"] | null;
}

const initialState: () => GridState = () => {
  const grid = generateBaseGrid({ empty: true });
  return { grid, targeting_filters: grid.targeting_filters };
};

export const gridSlice = createSlice({
  name: FeatureNames.Grid,
  initialState,
  reducers: {
    resetGrid: (state) => {
      const grid = generateBaseGrid({ empty: true });
      state.grid = grid;
      state.targeting_filters = grid.targeting_filters;
    },
    setGrid: (state, action: PayloadAction<BaseGrid>) => {
      state.grid = action.payload;
      state.targeting_filters = action.payload.targeting_filters;
    },
    setTargetingRules: (
      state,
      action: PayloadAction<BaseGrid["targeting_filters"]>
    ) => {
      state.targeting_filters = action.payload;
    },
    removeTargetingFilter: (state, action: PayloadAction<string>) => {
      const referenceField = action.payload as ReferenceFields;
      if (state.targeting_filters) {
        delete state.targeting_filters[referenceField];
      }
      if (state.grid?.targeting_filters) {
        delete state.grid.targeting_filters[referenceField];
      }
    },
    updateTargetingFilter: (
      state,
      action: PayloadAction<{ id: string; percentage: number }>
    ) => {
      if (state.targeting_filters) {
        const referenceField = action.payload.id as ReferenceFields;
        state.targeting_filters[referenceField] = {
          percentage: action.payload.percentage,
        };
      }
    },
    setTargetingFilters: (
      state,
      action: PayloadAction<BaseGrid["targeting_filters"]>
    ) => {
      state.targeting_filters = action.payload;
    },
    updateGridFilter: (
      state,
      action: PayloadAction<{ id: string; percentage: number }>
    ) => {
      if (state.grid && state.targeting_filters) {
        const referenceField = action.payload.id as ReferenceFields;
        if (!state.grid.targeting_filters[referenceField]) {
          state.grid.targeting_filters[referenceField] = { percentage: 0 };
        }
        state.grid.targeting_filters[referenceField].percentage =
          action.payload.percentage;
      }
    },
    setTargetingType: (state, action: PayloadAction<GridState["grid"]>) => {
      if (!state.grid || !action.payload) return;

      state.grid.targeting_type = action.payload.targeting_type;
      return state;
    },
    handleManualFilterChange: (
      state,
      action: PayloadAction<{ cellId: string; selectedTool: MapTool }>
    ) => {
      if (!state.grid) return;
      if (!state.grid.manual_inclusion_filters)
        state.grid.manual_inclusion_filters = [];
      const { cellId: id, selectedTool } = action.payload;
      if (selectedTool === "inclusion") {
        if (!state.grid.manual_inclusion_filters)
          state.grid.manual_inclusion_filters = [];
        if (!state.grid.manual_inclusion_filters.includes(id)) {
          state.grid.manual_inclusion_filters.push(id);
          // Remove id from exclusion filter if it exists
          state.grid.manual_exclusion_filters =
            state.grid.manual_exclusion_filters?.filter(
              (cell) => cell !== id
            ) ?? [];
        } else {
          state.grid.manual_exclusion_filters =
            state.grid.manual_exclusion_filters?.filter(
              (cell) => cell !== id
            ) ?? [];
        }
      } else if (selectedTool === "exclusion") {
        if (!state.grid.manual_exclusion_filters)
          state.grid.manual_exclusion_filters = [];
        if (!state.grid.manual_exclusion_filters.includes(id)) {
          state.grid.manual_exclusion_filters.push(id);
          // Remove id from inclusion filter if it exists
          state.grid.manual_inclusion_filters =
            state.grid.manual_inclusion_filters?.filter((cell) => cell !== id);
        } else {
          state.grid.manual_exclusion_filters =
            state.grid.manual_exclusion_filters?.filter((cell) => cell !== id);
        }
      }
    },
    handleBulkManualFilterChange: (
      state,
      action: PayloadAction<{ cellIds: string[]; selectedTool: MapTool }>
    ) => {
      if (!state.grid) return;
      if (!state.grid.manual_inclusion_filters)
        state.grid.manual_inclusion_filters = [];
      const { cellIds: ids, selectedTool } = action.payload;
      if (selectedTool === "inclusion") {
        if (!state.grid.manual_inclusion_filters)
          state.grid.manual_inclusion_filters = [];
        state.grid.manual_inclusion_filters = [
          ...new Set(state.grid.manual_inclusion_filters.concat(ids)),
        ];
        // Remove ids from exclusion filter if they exist
        if (state.grid.manual_exclusion_filters) {
          state.grid.manual_exclusion_filters =
            state.grid.manual_exclusion_filters.filter(
              (cell) => !ids.includes(cell)
            );
        }
      } else if (selectedTool === "exclusion") {
        if (!state.grid.manual_exclusion_filters)
          state.grid.manual_exclusion_filters = [];
        state.grid.manual_exclusion_filters = [
          ...new Set(state.grid.manual_exclusion_filters.concat(ids)),
        ];
        // Remove ids from inclusion filter if they exist
        if (state.grid.manual_inclusion_filters) {
          state.grid.manual_inclusion_filters =
            state.grid.manual_inclusion_filters.filter(
              (cell) => !ids.includes(cell)
            );
        }
      }
    },
    handleBulkManualFilterClear: (
      state,
      action: PayloadAction<{ cellIds: string[] }>
    ) => {
      if (!state.grid) return;
      if (!state.grid.manual_inclusion_filters)
        state.grid.manual_inclusion_filters = [];
      const { cellIds: ids } = action.payload;
      if (state.grid.manual_inclusion_filters) {
        state.grid.manual_inclusion_filters =
          state.grid.manual_inclusion_filters.filter(
            (cell) => !ids.includes(cell)
          );
      }
      if (state.grid.manual_exclusion_filters) {
        state.grid.manual_exclusion_filters =
          state.grid.manual_exclusion_filters.filter(
            (cell) => !ids.includes(cell)
          );
      }
    },
    handleManualFilterReset: (
      state,
      action: PayloadAction<{ selectedTool: MapTool }>
    ) => {
      if (!state.grid) return;
      if (!state.grid.manual_inclusion_filters)
        state.grid.manual_inclusion_filters = [];
      const { selectedTool } = action.payload;
      if (selectedTool === "inclusion") {
        state.grid.manual_inclusion_filters = [];
      } else if (selectedTool === "exclusion") {
        state.grid.manual_exclusion_filters = [];
      }
    },
    setTargetingMetrics: (
      state,
      action: PayloadAction<{
        households: number;
        coverage: number;
        targetedHouseholds: number;
      }>
    ) => {
      if (!state.grid) return;
      const { households, coverage, targetedHouseholds } = action.payload;
      state.grid.households = households;
      state.grid.targeting_coverage = coverage;
      state.grid.targeted_households = targetedHouseholds;
    },
  },
});

export const {
  resetGrid,
  setGrid,
  setTargetingRules,
  removeTargetingFilter,
  updateTargetingFilter,
  setTargetingFilters,
  updateGridFilter,
  setTargetingType,
  handleManualFilterChange,
  handleBulkManualFilterChange,
  handleBulkManualFilterClear,
  handleManualFilterReset,
  setTargetingMetrics,
} = gridSlice.actions;

export type GridSlice = typeof gridSlice.actions;

// Selectors
export const gridDataSelector = (state: RootState) => state.grid.grid;
export const targetingFiltersSelector = (state: RootState) =>
  state.grid.targeting_filters;
export const targetingTypeSelector = (state: RootState) =>
  state.grid.grid?.targeting_type;
export const manualInclusionFiltersSelector = (state: RootState) =>
  state.grid.grid?.manual_inclusion_filters;
export const manualExclusionFiltersSelector = (state: RootState) =>
  state.grid.grid?.manual_exclusion_filters;
