import { FC } from "react";
import { Home } from "lucide-react";
import { type Grid as GridType } from "honeygrid-types";

interface TargetingCoverageMetricRowProps {
  grid: GridType;
}

export const TargetingCoverageMetricRow: FC<
  TargetingCoverageMetricRowProps
> = ({ grid }) => {
  if (!grid) return null;

  const targetedHouseholds = grid.targeted_households ?? 0;
  const totalHouseholds = grid.households ?? 0;
  const percentage =
    totalHouseholds > 0 ? targetedHouseholds / totalHouseholds : 0;

  return (
    <div className="flex items-center gap-2">
      <Home className="h-4 w-4" />
      <p className="text-sm">
        {`${targetedHouseholds.toLocaleString("en-US", {
          notation: "compact",
          compactDisplay: "short",
        })}/${totalHouseholds.toLocaleString("en-US", {
          notation: "compact",
          compactDisplay: "short",
        })}`}{" "}
        households (
        {percentage.toLocaleString("en-US", {
          style: "percent",
          maximumFractionDigits: 1,
        })}
        )
      </p>
    </div>
  );
};
