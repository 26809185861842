import { FC, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

import { formatCurrency } from "@/utils";
import { MoreHorizontal, Trash2, Edit, MapPin } from "lucide-react";
import { Avatar, Paper, styled } from "@mui/material";
import { useAppSelector } from "@redux/hooks/useAppState";
import { selectedGridIdSelector } from "@/redux/store/slices/map/mapSlice";
import { Campaign, Location, Grid as GridType } from "honeygrid-types";
import { enqueueSnackbar } from "notistack";
import { cn } from "@/lib/utils";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Card as ShadcnCard, CardContent } from "@/components/ui/card";
import { DeleteConfirmationDialog } from "../DeleteConfirmationDialog";
import { TargetingAreasMetricRow } from "../TargetingAreasMetricRow";
import { TargetingCoverageMetricRow } from "../TargetingCoverageMetricRow";

/** Made a custom card component
 * as material UI adds a margin bottom on the last child
 * This is simply a wrapper that takes in the size of its children
 * 
 * We can add styles by using the sx props - same as material ui
 * 
 * @usage
 * <Card
      onClick={handlePreviewJob}
      sx={{
        borderRadius: '10px',
        padding: '16px',
        cursor: 'pointer',
        background: snapshot.isDragging ? '#F2F2F2' : '#FFF',
      }}
    >
 */

interface StaffCardProps {
  avatar?: string;
  /**
   * Image URL for the avatar
   */

  name: string;
}

interface StatsCardProps {
  metric: string;
  stat: string;
}

interface CampaignCardProps {
  campaign: Campaign;
  onChangeStatus?: () => void;
}

interface GridCardProps {
  grid: GridType;
  isSelected?: boolean;
}

interface LocationCardProps {
  location: Location;
  isSelected?: boolean;
  onRemove?: (locationId: string) => void;
}

const Card = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "left",
  fontWeight: "bold",
  borderRadius: "8px",
}));

const StaffCard: FC<StaffCardProps> = ({ avatar, name }) => {
  return (
    <div className="min-w-[120px] mr-2" data-testid="card-component">
      <ShadcnCard>
        <CardContent className="p-4">
          <div className="flex items-center gap-4">
            <Avatar className="h-10 w-10">
              {!avatar && <span className="uppercase">{name.charAt(0)}</span>}
              {avatar && (
                <img src={avatar} alt={name} className="aspect-square" />
              )}
            </Avatar>
            <span
              className="capitalize font-medium"
              data-testid="name-component"
            >
              {name}
            </span>
          </div>
        </CardContent>
      </ShadcnCard>
    </div>
  );
};

const StatsCard: FC<StatsCardProps> = ({ metric, stat }) => {
  return (
    <div className="space-y-2">
      <h3 className="text-xl font-semibold" data-testid="metric-text">
        {metric}
      </h3>
      <ShadcnCard>
        <CardContent className="p-4">
          <p
            className={cn(
              "text-5xl font-semibold text-center",
              metric === "Total Conversions" && "text-green-600"
            )}
            data-testid="stat-text"
          >
            {stat}
          </p>
        </CardContent>
      </ShadcnCard>
    </div>
  );
};

/****
 * 
 * This is a campaign card
 * 
 * 
 * Needs:
 * 
 * const [live, setLive] = useState(true);

    const changeStatus = () => {
        setLive(isLive => !isLive);
    };

 * @usage  
 * <CampaignCard
    name="test campaign"
    budget={100}
    live={live}
    leads={37}
    conversions={2}
    onChangeStatus={changeStatus}
    />
 */

const CampaignCard: FC<CampaignCardProps> = ({ campaign, onChangeStatus }) => {
  const { name, budget, status } = campaign;
  return (
    <ShadcnCard data-testid="campaign-card" className="m-1">
      <CardContent className="p-4 space-y-3">
        <h3 className="text-sm font-semibold">{name}</h3>
        <p className="text-sm text-muted-foreground">
          {formatCurrency(budget?.amount)}
        </p>
        <select
          onChange={onChangeStatus}
          value={status}
          className={cn(
            "h-8 w-24 rounded-full text-xs px-3",
            status === "active"
              ? "bg-green-700 text-white"
              : "bg-muted text-muted-foreground"
          )}
        >
          <option value="active">Live</option>
          <option value="paused">Paused</option>
          <option value="cancelled">Cancelled</option>
          <option value="archived">Archived</option>
        </select>
      </CardContent>
    </ShadcnCard>
  );
};

const GridCard: FC<GridCardProps> = ({ grid, isSelected }) => {
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const { workspaceId = "" } = useParams();
  const selectedGridId = useAppSelector(selectedGridIdSelector);
  // TODO Update delete grid mutation to react query
  // const [deleteGrid] = useDeleteGridMutation();

  const handleRemove = async () => {
    // await deleteGrid({ workspaceId, gridId: grid.id });
    enqueueSnackbar(`Removed ${grid.name}`, { variant: "success" });
    setDeleteConfirmationOpen(false);
  };

  const handleCardClick = () => {
    if (grid.id === selectedGridId) {
      navigate(`/${workspaceId}/honeygrid`, { replace: true });
    } else {
      navigate(`/${workspaceId}/honeygrid?gridId=${grid.id}`, {
        replace: true,
      });
    }
  };

  return (
    <>
      <ShadcnCard
        className={cn(
          "w-full transition-colors hover:bg-accent cursor-pointer",
          isSelected && "border-2 border-primary"
        )}
        onClick={handleCardClick}
        data-testid="grid-card-container"
      >
        <CardContent className="p-4">
          <div className="flex items-center justify-between">
            <h3 className="text-base font-medium truncate">{grid.name}</h3>
            <DropdownMenu>
              <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
                <Button variant="ghost" size="icon" className="h-8 w-8">
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem asChild>
                  <Link
                    to={grid.id}
                    className="flex items-center"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <Edit className="mr-2 h-4 w-4" />
                    <span>Edit</span>
                  </Link>
                </DropdownMenuItem>
                <DropdownMenuItem
                  className="text-destructive focus:text-destructive"
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteConfirmationOpen(true);
                  }}
                >
                  <Trash2 className="mr-2 h-4 w-4" />
                  <span>Remove</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
          <div className="mt-2 space-y-1 text-muted-foreground">
            <TargetingAreasMetricRow grid={grid} />
            <TargetingCoverageMetricRow grid={grid} />
          </div>
        </CardContent>
      </ShadcnCard>
      <DeleteConfirmationDialog
        isOpen={deleteConfirmationOpen}
        title={`${grid.name}`}
        message={`Are you sure you want to remove ${grid.name}? Any active campaigns using this grid will no longer be able to update their targeting.`}
        onRemove={handleRemove}
        onCancel={() => setDeleteConfirmationOpen(false)}
      />
    </>
  );
};

const LocationCard: FC<LocationCardProps> = ({
  location,
  isSelected,
  onRemove,
}) => {
  const navigate = useNavigate();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const { workspaceId = "" } = useParams();

  const handleCardClick = () => {
    navigate(`/${workspaceId}/locations/${location.place_id}`);
  };

  const handleRemove = async () => {
    onRemove?.(location.id);
    setDeleteConfirmationOpen(false);
  };

  return (
    <>
      <ShadcnCard
        className={cn(
          "w-full transition-colors hover:bg-accent cursor-pointer",
          isSelected && "border-2 border-primary"
        )}
        onClick={handleCardClick}
      >
        <CardContent className="p-4">
          <div className="flex items-center gap-2">
            <MapPin className="h-5 w-5 text-primary flex-shrink-0" />
            <div className="flex-1 min-w-0">
              <h3 className="text-base font-medium truncate">
                {location.name}
              </h3>
              <p className="text-sm text-muted-foreground truncate">
                {location.address}
              </p>
            </div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild onClick={(e) => e.stopPropagation()}>
                <Button variant="ghost" size="icon" className="h-8 w-8">
                  <MoreHorizontal className="h-4 w-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  className="text-destructive focus:text-destructive"
                  onClick={() => setDeleteConfirmationOpen(true)}
                >
                  <Trash2 className="mr-2 h-4 w-4" />
                  <span>Remove</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </CardContent>
      </ShadcnCard>
      <DeleteConfirmationDialog
        isOpen={deleteConfirmationOpen}
        title={location.name}
        message={`Are you sure you want to remove ${location.name}?`}
        onRemove={handleRemove}
        onCancel={() => setDeleteConfirmationOpen(false)}
      />
    </>
  );
};

export { Card, StaffCard, StatsCard, CampaignCard, GridCard, LocationCard };
export type {
  StaffCardProps,
  StatsCardProps,
  CampaignCardProps,
  GridCardProps,
  LocationCardProps,
};
