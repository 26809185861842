import { FC } from "react";
import { useNavigate } from "react-router-dom";

import { useEffectOnce } from "@/hooks/useEffectOnce";

export const Success: FC = () => {
  const navigate = useNavigate();

  useEffectOnce(() => {
    const redirect = localStorage.getItem("REDIRECT_SUCCESS");
    if (redirect) {
      navigate(redirect, { replace: true });
      localStorage.removeItem("REDIRECT_SUCCESS");
    } else {
      navigate("/", { replace: true });
    }
  });

  return <></>;
};
