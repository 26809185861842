import { useAuth } from "@/loaders/Auth";

export const API_BASE_URL = import.meta.env.VITE_API_URL;

export interface FetchOptions extends RequestInit {
  method?: string;
  body?: any;
}

export const useHoneyGridApiFetch = () => {
  const { getToken } = useAuth();

  return async (endpoint: string, options: FetchOptions = {}) => {
    const token = await getToken();
    const { body, ...rest } = options;

    const headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
      ...options.headers,
    };

    const response = await fetch(`${API_BASE_URL}/jwt/${endpoint}`, {
      ...rest,
      body: body ? JSON.stringify(body) : undefined,
      headers,
    });

    const data = await response.json();

    if (!response.ok) {
      throw data;
    }

    return data;
  };
};
