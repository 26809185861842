import { z } from "zod";

export const WorkspaceStatusValidator = z.enum(["active", "archived"]);
export const WorkspaceRoleValidator = z.enum(["owner", "editor", "viewer"]);

export const WorkspaceValidator = z.object({
  id: z.string(),
  account_id: z.string(),
  background_image: z.string().nullable(),
  created_at: z.string(),
  name: z.string(),
  status: WorkspaceStatusValidator,
  campaign_count: z.number(),
  connection_count: z.number(),
  grid_count: z.number(),
  landing_page_count: z.number(),
  location_count: z.number(),
});

export const BaseWorkspaceValidator = WorkspaceValidator.omit({
  id: true,
  created_at: true,
}).partial();

export const WorkspacePermissionsValidator = z.object({
  id: z.number(),
  workspace_id: z.string(),
  user_id: z.string().optional(),
  email: z.string(),
  role: WorkspaceRoleValidator,
  invitation_status: z.enum(["pending", "accepted"]).default("pending"),
  created_at: z.string(),
});

export const BaseWorkspacePermissionsValidator =
  WorkspacePermissionsValidator.omit({
    id: true,
    created_at: true,
  });

export const InviteUserValidator = z.object({
  email: z.string(),
  role: WorkspaceRoleValidator,
});

export type WorkspaceStatus = z.infer<typeof WorkspaceStatusValidator>;
export type WorkspaceRole = z.infer<typeof WorkspaceRoleValidator>;
export type Workspace = z.infer<typeof WorkspaceValidator>;
export type BaseWorkspace = z.infer<typeof BaseWorkspaceValidator>;
export type WorkspacePermission = z.infer<typeof WorkspacePermissionsValidator>;
export type BaseWorkspacePermission = z.infer<
  typeof BaseWorkspacePermissionsValidator
>;
export type InviteUser = z.infer<typeof InviteUserValidator>;
// Asset types that were in Workspace.ts
export enum AssetTypes {
  IMAGE = "image",
  VIDEO = "video",
  HEADLINE = "headline",
  DESCRIPTION = "description",
  CTA = "cta",
}

export interface Integration {
  platform: "google" | "facebook";
  refresh_token: string;
  access_token: string;
}

export interface AssetLibrary {
  id?: string;
  name: string;
  headlines: string[];
}

export interface Asset {
  id: string;
  url: string;
  type: AssetTypes;
  tags: string[];
}
