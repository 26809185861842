import { z } from "zod";
import {
  WorkspaceRoleValidator,
  WorkspacePermissionsValidator,
} from "./WorkspaceTypes";

export const UserValidator = z.object({
  id: z.string(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  email: z.string(),
  created_date: z.string().nullable(),
});

export const WorkspaceMemberValidator = z.object({
  user_id: UserValidator.shape.id.nullable(),
  role: WorkspaceRoleValidator,
  joined_at: z.string(),
  email: UserValidator.shape.email,
  invitation_status: WorkspacePermissionsValidator.shape.invitation_status,
  first_name: UserValidator.shape.first_name,
  last_name: UserValidator.shape.last_name,
});

export type User = z.infer<typeof UserValidator>;
export type WorkspaceMember = z.infer<typeof WorkspaceMemberValidator>;
