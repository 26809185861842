import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Workspace } from "honeygrid-types";
import { useHoneyGridApiFetch } from "@/queries/useHoneyGridApiFetch";
import { workspacesQueryKey } from "./useWorkspaces";

export const useCreateWorkspace = () => {
  const queryClient = useQueryClient();
  const authFetch = useHoneyGridApiFetch();

  return useMutation({
    mutationFn: (workspace: Partial<Workspace>) =>
      authFetch("workspaces", {
        method: "POST",
        body: workspace,
      }) as Promise<Workspace>,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: workspacesQueryKey });
    },
  });
};
