import { forwardRef } from "react";
import { LucideIcon, LucideProps } from "lucide-react";

export const HoneyGridIcon: LucideIcon = forwardRef<SVGSVGElement, LucideProps>(
  function HoneyGridIcon(props, ref) {
    return (
      <svg
        ref={ref}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
      >
        <path d="M13.3448 8.17241L10.7586 12.6519L5.58623 12.6519L3.00002 8.17241L5.58623 3.69297L10.7586 3.69297L13.3448 8.17241Z M13.4482 16.9656L10.862 21.4451L5.68962 21.4451L3.10341 16.9656L5.68962 12.4862L10.862 12.4862L13.4482 16.9656Z M21 12.5171L18.4138 16.9966L13.2414 16.9966L10.6552 12.5171L13.2414 8.0377L18.4138 8.0377L21 12.5171Z" />
      </svg>
    );
  }
);
