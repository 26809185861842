import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { FeatureNames } from "../names";
import { RootState } from "../../initStore";

export interface LocalMapViewState {
  bearing: number;
  latitude: number;
  longitude: number;
  pitch: number;
  zoom: number;
}

interface Coordinates {
  lat: number;
  lng: number;
}

export interface MapViewBounds {
  northEast: Coordinates;
  southWest: Coordinates;
}

export interface UIState {
  modalState: {
    isComingSoonDialogOpen: boolean;
    isWorkspaceEditorOpen: boolean;
    isWorkspaceSelectorOpen: boolean;
    isInviteUsersDialogOpen: boolean;
    isSyncToGoogleDialogOpen: boolean;
  };
  headerState: {
    isProfileButtonShown: boolean;
  };
  mapViewState: any;
  mapViewBounds: any;
}

const initialState: UIState = {
  modalState: {
    isComingSoonDialogOpen: false,
    isWorkspaceEditorOpen: false,
    isWorkspaceSelectorOpen: false,
    isInviteUsersDialogOpen: false,
    isSyncToGoogleDialogOpen: false,
  },
  headerState: {
    isProfileButtonShown: true,
  },
  mapViewState: null,
  mapViewBounds: null,
};

export const uiSlice = createSlice({
  name: FeatureNames.UI,
  initialState,
  reducers: {
    setIsComingSoonDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.modalState.isComingSoonDialogOpen = action.payload;
    },
    setIsWorkspaceEditorOpen: (state, action: PayloadAction<boolean>) => {
      state.modalState.isWorkspaceEditorOpen = action.payload;
    },
    setIsWorkspaceSelectorOpen: (state, action: PayloadAction<boolean>) => {
      state.modalState.isWorkspaceSelectorOpen = action.payload;
    },
    setIsInviteUsersDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.modalState.isInviteUsersDialogOpen = action.payload;
    },
    setIsProfileButtonShown: (state, action: PayloadAction<boolean>) => {
      state.headerState.isProfileButtonShown = action.payload;
    },
    setMapViewState: (state, action: PayloadAction<any>) => {
      state.mapViewState = action.payload;
    },
    setMapViewBounds: (state, action: PayloadAction<any>) => {
      state.mapViewBounds = action.payload;
    },
    setIsSyncToGoogleDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.modalState.isSyncToGoogleDialogOpen = action.payload;
    },
  },
});

export const {
  setIsComingSoonDialogOpen,
  setIsWorkspaceEditorOpen,
  setIsWorkspaceSelectorOpen,
  setIsInviteUsersDialogOpen,
  setIsProfileButtonShown,
  setMapViewState,
  setMapViewBounds,
  setIsSyncToGoogleDialogOpen,
} = uiSlice.actions;

// Selectors
export const isComingSoonDialogOpen = (state: RootState) =>
  state.ui.modalState.isComingSoonDialogOpen;
export const isWorkspaceEditorOpen = (state: RootState) =>
  state.ui.modalState.isWorkspaceEditorOpen;
export const isWorkspaceSelectorOpen = (state: RootState) =>
  state.ui.modalState.isWorkspaceSelectorOpen;
export const isInviteUsersDialogOpen = (state: RootState) =>
  state.ui.modalState.isInviteUsersDialogOpen;
export const isProfileButtonShown = (state: RootState) =>
  state.ui.headerState.isProfileButtonShown;
export const mapViewState = (state: RootState) => state.ui.mapViewState;
export const mapViewBounds = (state: RootState) => state.ui.mapViewBounds;
export const isSyncToGoogleDialogOpen = (state: RootState) =>
  state.ui.modalState.isSyncToGoogleDialogOpen;
